import React from "react"

// Code Hightlighter
import { PrismAsyncLight as SyntaxHighlighter } from "react-syntax-highlighter"
import scss from "react-syntax-highlighter/dist/esm/languages/prism/scss"
import coy from "react-syntax-highlighter/dist/esm/styles/prism/coy"

export default function ScssBlock(codeString, url) {
  codeString = `
  // Copyright ${new Date().getFullYear().toString()}, Johannes Eklund, All rights reserved.
  // You're free to share this piece of code if you would like to. If you find this code useful I would be really happy if you share it by using the link below.
  // https://www.johanneseklund.com${url}
  
${codeString}`
  SyntaxHighlighter.registerLanguage("scss", scss)
  return (
    <SyntaxHighlighter
      language="scss"
      style={coy}
      showLineNumbers={true}
      wrapLongLines={true}
    > 
      {codeString}
    </SyntaxHighlighter>
  )
}
