import React, { useState } from "react"
import "../scss/blog-post.scss"

// Components
import Layout from "../components/layout"
import TopSpacer from "../components/top-spacer/top-spacer"

// SEO
import SEO from "../seo/main.js"
import Breadcrumb from "../seo/breadcrumb"

// Code Hightlight
import HTMLBlock from "../components/code-hightlight/html"
import CSSBlock from "../components/code-hightlight/css"
import SCSSBlock from "../components/code-hightlight/scss"
import JavascriptBlock from "../components/code-hightlight/javascript"
import ReactBlock from "../components/code-hightlight/react"
import JSONBlock from "../components/code-hightlight/json"

export default function Code(props) {
  const codeObject = props.pageContext
  const crumbArray = [
    { title: "Blog", url: "/blog/" },
    { title: codeObject.title, url: codeObject.url },
  ]
  return (
    <>
      {codeObject ? (
        <Layout>
          <CodePost codeObject={codeObject} />
          <Breadcrumb location={props.location} array={crumbArray} />
        </Layout>
      ) : null}
    </>
  )
}

const CodePost = props => {
  const [activeIndex, setActiveIndex] = useState(0)
  const [copied, setCopied] = useState(false)
  const { codeObject } = props

  return (
    <>
      <SEO
        title={codeObject.seo_title}
        description={codeObject.seo_description}
        article
      />
      <TopSpacer />
      <div className="headline-area" key="headline-area">
        <h1>{codeObject.title}</h1>
      </div>
      <div className="summary-area" key="summary-area">
        <div className="featured">
          <div className="text green">
            <h3>
              <b>Specifications — </b>
              {codeObject.title}
            </h3>
            <ul>
              <li>
                Language
                <br />
                <span>{codeObject.header.specifications.language ? codeObject.header.specifications.language : ""}</span>
              </li>
              <br />
              <li>
                {codeObject.header.specifications.dependencies.length > 0 ? <>
                  Dependencies
                <br />
                  {codeObject.header.specifications.dependencies.map(
                    dependency => {
                      return (
                        <><a
                          key={dependency.url}
                          href={dependency.url}
                          target="_blank"
                          rel="noreferrer"
                        >
                          {dependency.title}
                        </a><br /></>
                      )
                    }
                  )}
                </> : null}
              </li>
              <br />
            </ul>
          </div>
          {codeObject.header.specifications.github ? (
            <div className="primary-cta outbound">
              <a
                href={codeObject.header.specifications.github}
                target="_blank"
                rel="noreferrer"
              >
                Go to GitHub repository
              </a>
            </div>
          ) : null}
        </div>
        <div className="summary">
          <p>
            <span>
              <b>How I use {codeObject.title}</b>
            </span>
          </p>
          {codeObject.header.description.map((text, i) => (
            <><p key={"foreword_" + i}>{text}</p><br /></>
          ))}
        </div>
      </div>
      <div className="code-wrapper">
        <div className="file-block">
          {codeObject.header.code.map((obj, i) => {
            return (
              <FileBlock
                key={obj.file + i}
                object={obj}
                index={i}
                activeIndex={activeIndex}
                setActiveIndex={setActiveIndex}
              />
            )
          })}
        </div>
        <div className="code-block">
          <CodeBlock
            url={codeObject.url}
            language={codeObject.header.code[activeIndex].language}
            codeString={codeObject.header.code[activeIndex].code}
          />
          <div className="clipboard-copy" onClick={() => copyToClipboard(codeObject.url, codeObject.header.code[activeIndex].code, setCopied)}>
            {copied ? "Copied!" : "Copy code"}
          </div>
        </div>
      </div>
    </>
  )
}

function copyToClipboard(url, codeString, setCopied) {
  codeString = `
  // Copyright ${new Date().getFullYear().toString()}, Johannes Eklund, All rights reserved.
  // You're free to share this piece of code if you would like to. If you find this code useful I would be really happy if you share it by using the link below.
  // https://www.johanneseklund.com${url}
  
  ` + codeString
  navigator.clipboard.writeText(codeString)
  setCopied(true)
  setTimeout(() => {
    setCopied(false)
  }, 1000);
}

function FileBlock({ object, index, activeIndex, setActiveIndex }) {
  return (
    <div
      className={activeIndex === index ? "file-item active" : "file-item"}
      onClick={() => setActiveIndex(index)}
    >
      {object.file + CodeExtension(object.language)}
    </div>
  )
}

function CodeBlock(props) {
  const { language, codeString, url } = props
  switch (language) {
    // Web
    case "html": return HTMLBlock(codeString, url)
    case "css": return CSSBlock(codeString, url)
    case "scss": return SCSSBlock(codeString, url)
    // JavaScript
    case "javascript": return JavascriptBlock(codeString, url)
    case "react": return ReactBlock(codeString, url)
    case "nodejs": return JavascriptBlock(codeString, url)
    // Data
    case "json": return JSONBlock(codeString, url)
    default: return null
  }
}

const CodeExtension = (value) => {
  switch (value) {
    // Web
    case "html": return ".html"
    case "css": return ".css"
    case "scss": return ".scss"
    // JavaScript
    case "javascript": return ".js"
    case "react": return ".js"
    case "nodejs": return ".js"
    // Data
    case "json": return ".json"
    default: return ""
  }
}

function test() {
  let columns = document.getElementsByClassName("columns-view__cell--clickable");
  let categories = ["Automotive", "Business"]
  let categoryLabels = ["Auto Parts", "Advertising"]
  for (var i = 0; i < columns.length; i++) {
    if (categories.includes(columns[i].innerText)) {
      columns[i].click()

      let labels = document.getElementsByClassName("columns-view__label-text");
      for (var y = 0; y < labels.length; y++) {
        if (labels[y] && categoryLabels.includes(labels[y].innerText)) {
          labels[y].click()
          console.log(labels[y].innerText)
        }
      }
    }
  }
}